import { useCallback, useState } from "react";

export const useOpacity = () => {
  const [opacity, setOpacity] = useState(1);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [isScrolledUp, setIsScrolledUp] = useState(false);

  const handleScroll = useCallback(() => {
    setScrolling(true);
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      // Scrolling down
      if (currentScrollY >= 80) {
        setIsScrolledUp(false);
      }
      setOpacity(0);
    } else {
      // Scrolling up
      setIsScrolledUp(true);
      setOpacity(1);
    }

    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  return {
    opacity,
    isScrolledUp,
    setOpacity,
    lastScrollY,
    setLastScrollY,
    scrolling,
    setScrolling,
    handleScroll,
  };
};
