import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { Box, Container, Divider, styled } from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase";
import { FlexBox } from "components/flex-box";
import { H6 } from "components/Typography";
import Link from "next/link";
import { layoutConstant } from "utils/constants";
import { navigationRoutes } from "common/constants/navigation-routes";
import LazyImage from "components/LazyImage";
import { blogsFlag, promotionalsFlag } from "common/constants/feature-toggles";
import modules from "modules";
import { useAppSelector } from "hooks/use-app-selector";
import { usePromotionalCounts } from "hooks/promotional/usePromotionalCounts";

const { selectors: promotionalSelectors } = modules.promotional;

const TopbarWrapper = styled(Box)(({ theme }) => ({
  fontSize: 12,
  height: layoutConstant.topbarHeight,
  background: theme.palette.background.paper,
  color: theme.palette.common.black,
  "& .topbarLeft": {
    "& .logo": { display: "none" },
    "& .title": { marginLeft: "10px" },
    "@media only screen and (max-width: 900px)": {
      "& .logo": { display: "block" },
      "& > *:not(.logo)": { display: "none" },
    },
  },
  "& .topbarRight": {
    "& .link": {
      paddingRight: 30,
      color: theme.palette.secondary.contrastText,
    },

    "@media only screen and (max-width: 900px)": {
      "& .link": { display: "none" },
    },
  },
  "& .menuItem": { minWidth: 100 },
  "& .marginRight": { marginRight: "1.25rem" },
  "& .handler": { height: layoutConstant.topbarHeight },
  "& .smallRoundedImage": { height: 15, width: 25, borderRadius: 2 },
  "& .menuTitle": { fontSize: 12, marginLeft: "0.5rem", fontWeight: 600 },
}));

const Topbar = () => {
  const { publishedCounts } = usePromotionalCounts();

  return (
    <TopbarWrapper>
      <Divider />
      <Container
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FlexBox className="topbarRight" alignItems="center">
          <Link href={navigationRoutes.aiBuilder} passHref>
            <TouchRipple sx={{ marginRight: 3 }}>
              <SmartToyOutlinedIcon
                color="secondary"
                sx={{ marginRight: 0.5 }}
              />
              <H6 sx={{ color: "#373F50" }}>AI Builder</H6>
            </TouchRipple>
          </Link>

          <Link href={navigationRoutes.diyBuilder} passHref>
            <TouchRipple sx={{ marginRight: 3 }}>
              <DesktopWindowsOutlinedIcon
                color="secondary"
                sx={{ marginRight: 0.5 }}
              />
              <H6 sx={{ color: "#373F50" }}>PC Builder</H6>
            </TouchRipple>
          </Link>

          <Link href={navigationRoutes.buildGallery} passHref>
            <TouchRipple>
              <CollectionsOutlinedIcon
                color="secondary"
                sx={{ marginRight: 0.5 }}
              />
              <H6 sx={{ color: "#373F50" }}>PC Gallery</H6>
            </TouchRipple>
          </Link>

          <Link href={navigationRoutes.pricelist} passHref>
            <TouchRipple sx={{ marginLeft: 3 }}>
              <ListAltOutlinedIcon
                color="secondary"
                sx={{ marginRight: 0.5 }}
              />
              <H6 sx={{ color: "#373F50" }}>Pricelist</H6>
            </TouchRipple>
          </Link>
          {blogsFlag && (
            <Link href={navigationRoutes.blogs} passHref>
              <TouchRipple sx={{ ml: 3 }}>
                <LazyImage
                  height="24px"
                  width="24px"
                  src={"/assets/images/badges/Frame.png"}
                />
                <H6 sx={{ marginLeft: 1, color: "#373F50" }}>
                  Blogs & Articles
                </H6>
              </TouchRipple>
            </Link>
          )}

          <Link href={navigationRoutes.powered_builds} passHref>
            <TouchRipple sx={{ marginLeft: 3 }}>
              <LazyImage
                height="20px"
                width="24px"
                src={"/assets/images/badges/powered_builds.png"}
              />
              <H6 sx={{ color: "#373F50", ml: 1 }}>Powered Builds</H6>
            </TouchRipple>
          </Link>

          {promotionalsFlag && (
            <Link href={navigationRoutes.promotionals} passHref>
              <TouchRipple
                sx={{
                  ml: 3,
                  pb: "1px",
                  height: "2em",
                  overflow: "visible",
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    overflow: "visible",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FlexBox sx={{ justifyContent: "end", mb: "-.4em" }}>
                    {!!publishedCounts && (
                      <LazyImage
                        height="14px"
                        width="51px"
                        objectFit="contain"
                        src="/assets/images/badges/hot-deals.png"
                      />
                    )}
                  </FlexBox>
                  <FlexBox>
                    <LazyImage
                      height="20px"
                      width="24px"
                      src={"/assets/images/badges/promo.png"}
                    />
                    <H6 sx={{ color: "#373F50", ml: 1 }}>Promos</H6>
                  </FlexBox>
                </div>
              </TouchRipple>
            </Link>
          )}

          <Link href={navigationRoutes.poweredByAsus} passHref>
            <TouchRipple sx={{ ml: 3 }}>
              <H6 sx={{ marginRight: 1, color: "#373F50" }}>Powered by</H6>
              <Box
                sx={{
                  height: "inherit",
                  pb: ".1em",
                  mt: 0.5,
                }}
              >
                <LazyImage
                  height="10px"
                  width="52px"
                  src={"/assets/images/asus_text1.png"}
                />
              </Box>
            </TouchRipple>
          </Link>

          <Link href={navigationRoutes.extra_service} passHref>
            <TouchRipple sx={{ marginLeft: 3 }}>
              <H6 sx={{ color: "#CF151A" }}>PC Worth Extra Service </H6>
            </TouchRipple>
          </Link>
        </FlexBox>
      </Container>
    </TopbarWrapper>
  );
};

export default Topbar;
