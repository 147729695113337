import SearchOutlined from "@mui/icons-material/SearchOutlined";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  InputAdornment,
  TextField,
  debounce,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  colors,
  formatCurrency,
  formatCurrencySRP,
  _,
  productDetailsPageUrl,
  ProductSortDirectionEnum,
} from "common";
import { FC, useCallback, useEffect, useRef, useState } from "react";

import BazarButton from "components/BazarButton";
import { H6, Paragraph, Span } from "components/Typography";
import { useSearchBar, useSearchPage } from "hooks";
import { Product } from "modules/products/types";
import BazarImage from "components/BazarImage";
import { FlexRow } from "components/flex-box";
import Link from "next/link";
import { useRouter } from "next/router";
import modules from "modules";
import { useAppSelector } from "hooks/use-app-selector";
import { useAppDispatch } from "hooks/use-app-dispatch";

// styled components
// also used in the GrocerySearchBox component
export const SearchOutlinedIcon = styled(SearchOutlined)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginRight: 6,
}));

// also used in the GrocerySearchBox component
export const SearchResultCard = styled(Card)(() => ({
  zIndex: 99,
  top: "100%",
  width: "100%",
  position: "absolute",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
}));

type Props = {
  isFocus?: boolean;
};

const { actions: productActions, selectors: productSelectors } =
  modules.products;

const SearchBox: FC<Props> = ({ isFocus }) => {
  const router = useRouter();
  const parentRef = useRef();
  const dispatch = useAppDispatch();
  const homeKeyword = useAppSelector(productSelectors.selectHomeKeyword);

  const { isSearchBarLoading, searchBarItems, searchBarRequest } =
    useSearchBar();
  const { queries, subCategoryId, subCategoryClearKeywordState } =
    useSearchPage();

  const [keywordState, setKeywordState] = useState("");
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);

  const changeKeyword = (val: string) => {
    dispatch(productActions.changeKeyword(val));
  };

  //TODO: confirm removal if new product filter is stable
  // const search = debounce((keyword) => {
  //   searchBarRequest(keyword);
  // }, 500);

  const handleChangeInput = useCallback((e) => {
    const keyword = e.target?.value;
    changeKeyword(keyword);
    //TODO: confirm removal if new product filter is stable
    // search(keyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchClick = useCallback(() => {
    if (keywordState !== "" && keywordState !== undefined) {
      const cleanKeyword: string = keywordState?.replace(/\//g, "");
      const cleanKeywordState: string = keywordState?.replace(/\//g, "");

      subCategoryClearKeywordState(true);
      dispatch(productActions?.changeMainCategoryId(null || undefined));

      router.push({
        pathname: `/product/search/${cleanKeyword || cleanKeywordState}`,
        query: {
          limit: 48,
          sort_direction:
            queries.sort_direction || ProductSortDirectionEnum.Ascending,
        },
      });
    }
  }, [
    keywordState,
    subCategoryClearKeywordState,
    dispatch,
    router,
    queries.sort_direction,
  ]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e?.key == "Enter") {
      onSearchClick();
    }
  };

  const SearchButton = (
    <InputAdornment position="end">
      <BazarButton
        variant="contained"
        color="secondary"
        sx={{
          position: "absolute",
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 120,
          height: "100%",
        }}
        onClick={() => onSearchClick()}
      >
        <Paragraph>Search</Paragraph>
      </BazarButton>
    </InputAdornment>
  );

  const SearchIcon = <SearchOutlinedIcon sx={{ ml: 1 }} fontSize="small" />;

  const productCellOptionLabel = useCallback(
    (props, option: Product, state) => {
      return (
        <Link
          passHref
          key={option?.slug}
          href={productDetailsPageUrl(option?.slug, option?.product_uuid)}
        >
          <Card
            elevation={0}
            onClick={() => setOptionsVisible(false)}
            sx={{
              cursor: "pointer",
              "&:hover": { backgroundColor: colors.grey[100] },
            }}
          >
            <FlexRow m={1}>
              <BazarImage
                height={50}
                src={option?.img_thumbnail}
                alt="pcw_product"
              />
              <Box ml={2}>
                <H6>{option?.product_name}</H6>
                <Box fontSize="16px" fontWeight="600" color="primary.main">
                  {formatCurrency(option?.amount)}
                </Box>
                <Span
                  fontSize="12px"
                  color="grey.600"
                  mt={-1}
                  sx={{ textDecoration: "line-through" }}
                >
                  {formatCurrencySRP(option?.amount)}
                </Span>
              </Box>
            </FlexRow>
            <Divider />
          </Card>
        </Link>
      );
    },
    []
  );

  const handleScroll = () => {
    setOptionsVisible(false);
  };

  useEffect(() => {
    setKeywordState(homeKeyword);
  }, [homeKeyword]);

  //if on first load it has sub categid remove keyword
  useEffect(() => {
    if (subCategoryId) {
      setKeywordState("");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      position="relative"
      flex="1 1 0"
      maxWidth="670px"
      mx="auto"
      {...{ ref: parentRef }}
    >
      <Autocomplete
        freeSolo
        open={optionsVisible}
        inputValue={keywordState}
        onOpen={() => setOptionsVisible(true)}
        onClose={() => setOptionsVisible(false)}
        isOptionEqualToValue={(option, value) =>
          option?.product_name === value?.product_name
        }
        options={searchBarItems || []}
        loading={isSearchBarLoading}
        getOptionLabel={(option: Product) =>
          option?.product_name ? option?.product_name : (option as string)
        }
        renderOption={productCellOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            autoFocus={isFocus}
            onKeyDown={(e) => onKeyPress(e)}
            variant="outlined"
            placeholder="Search for product(s)"
            onChange={handleChangeInput}
            InputProps={{
              ...params.InputProps,
              sx: {
                height: 44,
                borderRadius: 300,
                color: "grey.700",
                fontSize: "16px",
                overflow: "hidden",
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey.700",
                },
                "&.Mui-focused:not(:hover) .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey.700",
                },
              },
              endAdornment: SearchButton,
              startAdornment: SearchIcon,
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchBox;
