import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const IconMenuSignIn = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="18" height="17" viewBox="0 0 18 17" fill="none">
            <g clipPath="url(#clip0_244_136)">
                <path d="M15.9564 9.0061C15.9564 10.996 15.9566 12.9862 15.9564 14.9761C15.9564 16.4131 14.9724 17.6349 13.5692 17.9401C13.3896 17.9791 13.2071 17.9989 13.0227 17.9989C10.4702 17.9984 7.91745 18.0028 5.36495 17.997C3.94196 17.9938 2.71049 16.9552 2.44137 15.5589C2.36674 15.172 2.37547 14.7828 2.37547 14.3928C2.37547 13.926 2.37203 13.459 2.37823 12.9921C2.38672 12.3632 2.85033 11.9124 3.46986 11.9221C3.94702 11.9297 4.38514 12.3317 4.45265 12.8231C4.46666 12.9256 4.47332 13.0282 4.47332 13.1318C4.4724 13.7288 4.47079 14.3258 4.47492 14.9228C4.47929 15.5506 4.85219 15.9166 5.48412 15.9171C7.49333 15.918 9.50231 15.9178 11.5115 15.9182C11.9593 15.9182 12.4071 15.9196 12.8548 15.9194C13.4679 15.9194 13.8698 15.5272 13.8851 14.9148C13.8861 14.8803 13.8858 14.8459 13.8858 14.8115C13.8851 10.9232 13.8838 7.03501 13.8845 3.14701C13.8845 2.89626 13.85 2.65722 13.6952 2.45217C13.5166 2.21566 13.2794 2.0827 12.9788 2.08247C11.647 2.08155 10.3152 2.08293 8.98359 2.08247C7.90826 2.08178 6.83293 2.08178 5.7576 2.07467C5.58538 2.07352 5.4134 2.05905 5.24393 2.09556C4.76563 2.19866 4.4639 2.58856 4.46849 3.08042C4.47354 3.61613 4.47584 4.15185 4.47148 4.68756C4.46712 5.20375 4.1895 5.5909 3.74196 5.74658C3.13438 5.95784 2.39682 5.47058 2.38994 4.84554C2.38305 4.23405 2.34034 3.62279 2.37662 3.01084C2.41519 2.36101 2.57409 1.75044 2.96951 1.21817C3.42761 0.601398 4.04346 0.230555 4.78423 0.0525964C4.92682 0.0183824 5.0685 0.00299762 5.215 0.00299762C7.81687 0.00391611 10.4192 0.0075901 13.0213 1.2504e-05C13.574 -0.00159486 14.075 0.151794 14.5283 0.443876C15.2831 0.93022 15.7761 1.60669 15.921 2.51003C15.9446 2.65699 15.9557 2.80579 15.9557 2.9555C15.9547 4.97229 15.955 6.98908 15.955 9.00587H15.9561L15.9564 9.0061Z" fill="#AEB4BE" />
                <path d="M8.62467 7.93058C8.52846 7.84171 8.44464 7.76961 8.3668 7.69177C8.06921 7.39417 7.76725 7.10049 7.47861 6.7944C7.17597 6.47361 7.07264 6.09335 7.22809 5.67199C7.48252 4.98335 8.37713 4.7721 8.89976 5.28668C9.90574 6.27728 10.9028 7.2773 11.8952 8.28144C12.3003 8.69132 12.2821 9.32049 11.8784 9.72715C11.2587 10.3517 10.6403 10.9781 10.0187 11.6009C9.65384 11.9664 9.28369 12.3267 8.91606 12.6895C8.51514 13.0854 7.8777 13.0914 7.47655 12.7026C7.05909 12.2983 7.0398 11.6519 7.43981 11.2376C7.79595 10.8688 8.15945 10.5072 8.51881 10.1416C8.54705 10.1129 8.58655 10.0913 8.59482 10.0429C8.54774 10.0167 8.49723 10.03 8.44946 10.03C5.98904 10.0289 3.52862 10.0291 1.0682 10.028C0.51848 10.028 0.125823 9.71039 0.0204254 9.18593C-0.0994383 8.5896 0.31687 8.02817 0.923307 7.96984C1.01837 7.96066 1.11412 7.95538 1.20942 7.95538C3.62001 7.95469 6.03083 7.95492 8.44143 7.95423C8.49034 7.95423 8.5404 7.96364 8.62467 7.92989V7.93058Z" fill="#AEB4BE" />
            </g>
            <defs>
                <clipPath id="clip0_244_136">
                    <rect width="15.9563" height="18" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};