import { Button, Container, Dialog, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Slide, Typography } from "@mui/material"
import { branchMenuFlag } from "common/constants/feature-toggles"
import { useCallback, useEffect, useState } from "react"
import useWindowSize from "hooks/useWindowSize";
import { IconStore } from "components/icons/IconStore";
import { iconStyle } from "components/mobile-navigation/styles";
import { makeStyles } from "@mui/styles";
import { colors } from "common";
import { BranchListHeader } from "./BranchListHeader";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles({
  branchButton: {
    position: 'fixed',
    padding: 15,
    bottom: 20,
    left: 20,
    zIndex: 1,
    background: 'white',
    transition: 'opacity 0.4s ease',
    boxShadow: '1px 1px 5px grey',
    '&:hover': {
      background: 'white'
    }
  },
  branchList: {
    color: colors.primary,
    padding: '20',
  },
  branchName: {
    color: colors.skyBlue
  },
  branchListItem: {
    border: `1px solid #D8E0E9`,
    borderRadius: '5px',
    marginTop: 10,
    paddingRight: 200
  },
  itemHoverCursor: {
    cursor: 'pointer'
  }
})

// Fancy Dialog Transistion
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const BranchMenu = () => {
  const classes = useStyles()

  const [branchOpen, toggleBranchOpen] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState<string>('')

  // Sample branches
  const branchList = [
    { branchname: 'Sampaloc' },
    { branchname: 'Alabang' },
    { branchname: 'Cebu' }
  ]

  useEffect(() => {
    !selectedBranch ?
      setSelectedBranch(branchList[0].branchname) : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!branchMenuFlag) {
    return null
  }

  return !isMobile ? (
    <Container>
      <Button
        onClick={() => toggleBranchOpen(!branchOpen)}
        className={classes.branchButton}
      >
        <IconStore sx={iconStyle} />
        <Typography variant={'h6'} marginLeft={2} fontWeight={'bold'}>
          {`You're in `}
          <span className={classes.branchName}>
            {selectedBranch}
          </span>
          {' Branch.'}
        </Typography>
      </Button>
      <Dialog open={branchOpen} TransitionComponent={Transition}>
        <List className={classes.branchList} sx={{ padding: branchOpen ? '10px' : '15px' }}>
          <ListItem
            disablePadding
          >
            <BranchListHeader
              toggleBranchOpen={toggleBranchOpen}
              branch={selectedBranch}
              branchOpen={branchOpen}
            />
          </ListItem>
          {branchList.map(b => (
            <ListItemButton
              className={classes.branchListItem}
              key={b.branchname}
              onClick={() => {
                toggleBranchOpen(!branchOpen)
                setSelectedBranch(b.branchname)
              }}
              sx={{
                opacity: !branchOpen ? 0 : 1,
                height: !branchOpen ? '0px' : 'initial',
                transition: 'all 0.4s'
              }}
            >
              <ListItemIcon >
                <IconStore fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {b.branchname == selectedBranch ?
                  <Typography fontWeight={600}>
                    {`You're in `}
                    <span className={classes.branchName}>
                      {b.branchname}
                    </span>
                    {' Branch.'}
                  </Typography> :
                  <Typography fontWeight={600}>
                    {`Switch to `}
                    <span className={classes.branchName}>
                      {b.branchname}
                    </span>
                    {' Branch.'}
                  </Typography>}
              </ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Dialog>
    </Container>
  ) : null
}