import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const IconMenuPricelist = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
        >
            <path d="M10.2776 6.22218H15.6109V7.99996H10.2776V6.22218ZM10.2776 9.77774H15.6109V11.5555H10.2776V9.77774ZM10.2776 13.3333H15.6109V15.1111H10.2776V13.3333ZM6.72206 6.22218H8.49984V7.99996H6.72206V6.22218ZM6.72206 9.77774H8.49984V11.5555H6.72206V9.77774ZM6.72206 13.3333H8.49984V15.1111H6.72206V13.3333ZM18.3665 2.66663H3.9665C3.52206 2.66663 3.1665 3.02218 3.1665 3.46663V17.8666C3.1665 18.2222 3.52206 18.6666 3.9665 18.6666H18.3665C18.7221 18.6666 19.1665 18.2222 19.1665 17.8666V3.46663C19.1665 3.02218 18.7221 2.66663 18.3665 2.66663ZM17.3887 16.8888H4.94428V4.4444H17.3887V16.8888Z" fill="#AEB4BE" />
        </SvgIcon>
    );
};