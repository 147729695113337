import React, { useState, useEffect, FC, useRef, useCallback } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LazyImage from "components/LazyImage";
import { useIsMobile } from "hooks";
import { FlexBox } from "components/flex-box";
import { navigationRoutes } from "common/constants/navigation-routes";
import Link from "next/link";
import router from "next/router";
import { promotionalsFlag } from "common/constants/feature-toggles";
import { usePromotionalCounts } from "hooks/promotional/usePromotionalCounts";

const PromotionalFloater: FC = () => {
  const { publishedCounts, getPublishedCounts } = usePromotionalCounts();

  const floaterRef = useRef<HTMLDivElement>(null);
  const animationFrameId = useRef<number | null>(null);
  const positionRef = useRef({
    x: window.innerWidth * 0.75,
    y: window.innerHeight * 0.78,
  }); // Initial position

  const isMobile = useIsMobile();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setIsDragging(true);
  };

  // coded this way to prevent page scroll when dragging floater
  const handleTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (animationFrameId.current) return;

      animationFrameId.current = requestAnimationFrame(() => {
        if (isDragging) {
          const touch = e.touches[0];
          const newX = touch.clientX - 30; // Center the touch on the icon
          const newY = touch.clientY - 30;

          positionRef.current = { x: newX, y: newY };
          floaterRef.current.style.transition = "transform .3s linear";
          floaterRef.current.style.transform = `translate(${newX}px, ${newY}px)`;
        }
      });
      animationFrameId.current = null;
      floaterRef.current.style.transition = "none";
    },
    [isDragging]
  );

  const handleTouchEnd = () => {
    setIsDragging(false);

    const { innerWidth, innerHeight } = window;
    const snapX =
      positionRef.current.x <= innerWidth / 2 ? 30 : innerWidth - 80;
    const snapY = Math.max(
      0,
      Math.min(positionRef.current.y, innerHeight - 60)
    );

    // Apply CSS transition for snapping
    if (floaterRef.current) {
      floaterRef.current.style.transition = "transform 0.3s ease";
      floaterRef.current.style.transform = `translate(${snapX}px, ${snapY}px)`;

      // Update position ref after snapping
      positionRef.current = { x: snapX, y: snapY };

      // Remove the transition after it completes snapping to edge
      setTimeout(() => {
        if (floaterRef.current) {
          floaterRef.current.style.transition = "";
        }
      }, 300);
    }
  };

  const closeFloater = () => {
    setIsVisible(false);
    localStorage.setItem("promoFloaterClosed", "true"); // Track the close action
  };

  // keeps floater closed when navigating to other pages except for homepage
  useEffect(() => {
    const wasClosed = localStorage.getItem("promoFloaterClosed");
    const isHomepage = router.pathname === "/";

    if (
      publishedCounts &&
      promotionalsFlag &&
      isMobile &&
      (!wasClosed || isHomepage)
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [isMobile, publishedCounts]);

  // Treat this as global api call because it is used on ShopLayout1 anyway which is a main component
  useEffect(() => {
    getPublishedCounts();
  }, [getPublishedCounts]);

  return (
    isVisible && (
      <Box
        ref={floaterRef}
        sx={{
          willChange: "transform",
          position: "fixed",
          top: 0,
          left: 0,
          transform: `translate(${positionRef.current.x}px, ${positionRef.current.y}px)`,
          zIndex: 3,
          width: 60,
          height: 60,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          touchAction: "none",
          justifyContent: "center",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <FlexBox sx={{ alignItems: "end" }}>
          {/* todo: only show if promotionals.list from slice has length */}
          {!!publishedCounts && (
            <Box
              sx={{
                height: "1em",
                width: "1.5em",
                justifyContent: "center",
                borderRadius: "50%",
                background: "red",
                zIndex: 3,
                display: "flex",
                alignItems: "center",
                m: "0 -1em 1em 0",
              }}
            >
              <Typography color="white" fontSize=".6em">
                {publishedCounts}
              </Typography>
            </Box>
          )}
          <FlexBox flexDirection="column">
            <FlexBox
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <IconButton
                size="small"
                onClick={closeFloater}
                sx={{
                  color: "white",
                  backgroundColor: "red",
                  height: ".7em",
                  width: ".7em",
                }}
              >
                <CloseIcon sx={{ fontSize: ".6em", ml: ".1em" }} />
              </IconButton>
            </FlexBox>
            <Link href={navigationRoutes.promotionals} passHref>
              <LazyImage
                src="/assets/images/promo_float.png"
                alt="promotionals"
                width={86}
                height={86}
                style={{ border: "1px solid black" }}
              />
            </Link>
          </FlexBox>
        </FlexBox>
      </Box>
    )
  );
};

export default PromotionalFloater;
