import { ListItemText, Typography } from "@mui/material"
import { FC } from "react"

type Props = {
    title?: String
}

export const MenuDrawerTitle: FC<Props> = ({ title }) => {
    return (
        <ListItemText sx={{ opacity: 0.8, paddingLeft: '20px' }}>
            <Typography fontWeight={'bold'}>{title}</Typography>
        </ListItemText>
    )
}