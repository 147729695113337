import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from "react";

export const IconMenuHome = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_209_217)">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5302 1.18192C10.2183 0.939359 9.78165 0.939359 9.46979 1.18192L1.69706 7.22735C1.48669 7.39097 1.36365 7.64255 1.36365 7.90906V17.409C1.36365 18.0962 1.63662 18.7552 2.1225 19.241C2.60839 19.7269 3.2674 19.9999 3.95455 19.9999H7.3956C7.40008 20 7.40457 20 7.40907 20C7.41357 20 7.41807 20 7.42255 19.9999H12.5774C12.5819 20 12.5864 20 12.5909 20C12.5954 20 12.5999 20 12.6043 19.9999H16.0454C16.7326 19.9999 17.3916 19.7269 17.8774 19.241C18.3633 18.7552 18.6363 18.0962 18.6363 17.409V7.90906C18.6363 7.64255 18.5132 7.39097 18.3029 7.22735L10.5302 1.18192ZM13.4545 18.2727H16.0454C16.2744 18.2727 16.4941 18.1816 16.6561 18.0197C16.818 17.8578 16.909 17.6381 16.909 17.409V8.33145L9.99998 2.95773L3.09091 8.33145V17.409C3.09091 17.6381 3.18191 17.8578 3.34386 18.0197C3.50583 18.1816 3.72549 18.2727 3.95455 18.2727H6.54544V10.5001C6.54544 10.0231 6.93211 9.63643 7.40907 9.63643H12.5909C13.0679 9.63643 13.4545 10.0231 13.4545 10.5001V18.2727ZM8.27271 18.2727H11.7272V11.3637H8.27271V18.2727Z" fill="#AEB4BE" />
            </g>
            <defs>
                <clipPath id="clip0_209_217">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};