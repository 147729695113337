import { Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { formatCurrency, formatCurrencySRP, _, productDetailsPageUrl } from "common";
import BazarButton from "components/BazarButton";
import BazarIconButton from "components/BazarIconButton";
import { FlexBox } from "components/flex-box";
import ShoppingBagOutlined from "components/icons/ShoppingBagOutlined";
import LazyImage from "components/LazyImage";
import { H5, Span } from "components/Typography";
import Link from "next/link";
import React from "react";
import Image from "next/image";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { Add, DeleteForever, Remove } from "@mui/icons-material";
import { useCart } from "hooks/use-cart";

type MiniCartProps = { toggleSidenav?: () => void };

const MiniCart: React.FC<MiniCartProps> = ({ toggleSidenav }) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const {
    cart,
    cartLength,
    cartTotalAmount,
    removeFromCart,
    addQuantity,
    subtractQuantity,
  } = useCart();

  return (
    <Box width="380px" sx={{ zIndex: 200 }}>
      <Box
        overflow="auto"
        height={`calc(100vh - ${cartLength ? "80px - 3.25rem" : "0px"})`}
      >
        <FlexBox
          alignItems="center"
          m="0px 20px"
          height="74px"
          color="secondary.main"
        >
          <ShoppingBagOutlined color="inherit" />
          <Box fontWeight={600} fontSize="16px" ml={1}>
            {`${cartLength} item(s)`}
          </Box>
        </FlexBox>

        <Divider />

        {_.isEmpty(cart) && (
          <FlexBox
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            height="calc(100% - 74px)"
          >
            <LazyImage
              src="/assets/images/logos/shopping-bag.svg"
              width={90}
              height={100}
            />
            <Box
              component="p"
              mt={2}
              color="grey.600"
              textAlign="center"
              maxWidth="200px"
            >
              Your shopping bag is empty. Start shopping
            </Box>
          </FlexBox>
        )}

        {_.map(cart, (item, index) => (
          <FlexBox
            py={2}
            px={2.5}
            key={item?.product_slug}
            alignItems="center"
            borderBottom={`1px solid ${palette.divider}`}
            sx={{
              maxWidth: "380px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {/* <FlexBox alignItems="center" flexDirection="column">
              <Box fontWeight={600} fontSize="17px" my="3px">
                x {item.quantity}
              </Box>
            </FlexBox> */}

            <FlexBox alignItems="center" flexDirection="column">
              <BazarButton
                color="primary"
                variant="outlined"
                onClick={() => addQuantity(item?.product_slug)}
                disabled={item?.quantity >= (item?.stocks_left || 999)}
                sx={{ height: "20px", width: "20px", borderRadius: "200px" }}
              >
                <Add fontSize="small" />
              </BazarButton>

              <Box fontWeight={600} fontSize="15px" my="3px">
                {item?.quantity || 0}
              </Box>

              <BazarButton
                color="primary"
                variant="outlined"
                disabled={item?.quantity === 1}
                onClick={() => subtractQuantity(item?.product_slug)}
                sx={{ height: "20px", width: "20px", borderRadius: "200px" }}
              >
                <Remove fontSize="small" />
              </BazarButton>
            </FlexBox>

            <Link href={productDetailsPageUrl(item?.product_slug, item?.product_uuid)}>
              <a>
                <Box
                  marginX={1}
                  sx={{
                    width: 100,
                    height: 80,
                    position: "relative",
                  }}
                >
                  <Image
                    alt={item?.product_name}
                    src={
                      item?.img_thumbnail || "/assets/images/sad_red_hair.png"
                    }
                    layout="fill"
                    objectFit="contain"
                  />
                </Box>
              </a>
            </Link>

            <Box
              flex="1 1 0"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <Link href={productDetailsPageUrl(item?.product_slug, item?.product_uuid)}>
                <a>
                  <H5 className="title" fontSize="14px">
                    {item?.product_name}
                  </H5>
                </a>
              </Link>

              <Box fontWeight={600} fontSize="17px" color="secondary" mt={0.5}>
                {formatCurrency(item?.amount * item?.quantity)}
              </Box>
              <FlexBox alignItems="center">
                <Span
                  fontSize="12px"
                  color="grey.600"
                  sx={{ textDecoration: "line-through" }}
                >
                  {formatCurrencySRP(item?.amount * item?.quantity)}
                </Span>
              </FlexBox>
            </Box>

            <BazarIconButton
              ml={2.5}
              size="small"
              onClick={() => removeFromCart(item.product_slug, index)}
            >
              <DeleteForever color="error" />
            </BazarIconButton>
          </FlexBox>
        ))}
      </Box>
      <Box p={2.5}>
        <Link href="/cart" passHref>
          <BazarButton
            fullWidth
            color="primary"
            variant="outlined"
            sx={{ height: 40 }}
            onClick={toggleSidenav}
          >
            {cartLength
              ? `View Cart (${formatCurrency(cartTotalAmount || 0)})`
              : `View Order History`}
          </BazarButton>
        </Link>
      </Box>
    </Box>
  );
};

MiniCart.defaultProps = {
  toggleSidenav: () => {},
};

export default MiniCart;
