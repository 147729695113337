import { Box, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavBarItemList } from "common/types/mobile-navbar";
import { FlexBox } from "components/flex-box";
import LazyImage from "components/LazyImage";
import { usePromotionalCounts } from "hooks/promotional/usePromotionalCounts";
import { MainCategoryItem } from "modules/category/types";
import { FC, useEffect, useMemo } from "react";

type Props = {
  // Omit icon prop type from MainCategoryItem to prevent ts error
  item?: NavBarItemList & Omit<MainCategoryItem, "icon">;
  key?: string;
  isMainCateg?: boolean;
  onCategoryClick?: (mainCateg: string) => void;
};

export const MainMenuDrawerItem: FC<Props> = ({
  item,
  isMainCateg = false,
  onCategoryClick,
}) => {
  const { publishedCounts } = usePromotionalCounts();
  const extraServiceBool = useMemo(
    () => item.title === "PC Worth Extra Service",
    [item.title]
  );

  const isPromotional = useMemo(() => item.title === "Promos", [item.title]);

  const iconSRC = useMemo(
    () => (isMainCateg ? item.icon_sidedrawer : item.icon),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMainCateg]
  );

  if (isMainCateg) {
    return item?.is_visible_sidedrawer ? (
      <ListItemButton onClick={() => onCategoryClick(item.name)}>
        <ListItemIcon>
          <LazyImage
            width={24}
            height={24}
            objectFit="contain"
            src={iconSRC || "/assets/images/sad_red_hair.png"}
            alt="kachi"
          />
        </ListItemIcon>
        <ListItemText
          sx={{
            opacity: 0.8,
          }}
        >
          {item.name}
        </ListItemText>
      </ListItemButton>
    ) : null;
  }

  return (
    <ListItemButton href={item.href} onClick={item.onClick}>
      {extraServiceBool && (
        <Box mr={3.5}>
          <LazyImage
            sx={{
              width: "100%",
              borderRadius: "8.8px",
              border: "2px solid red",
            }}
            width={28}
            height={28}
            alt={`/assets/images/extra-service/pc-worth-extra-service-seal.png`}
            loading="eager"
            objectFit="contain"
            src={"/assets/images/extra-service/pc-worth-extra-service-seal.png"}
          />
        </Box>
      )}

      {isPromotional && (
        <ListItemIcon>
          <LazyImage
            width={24}
            height={24}
            objectFit="contain"
            src={iconSRC || "/assets/images/sad_red_hair.png"}
            alt="kachi"
          />
        </ListItemIcon>
      )}

      {!isPromotional && !extraServiceBool && (
        <ListItemIcon>
          <item.icon />
        </ListItemIcon>
      )}

      <FlexBox
        sx={{
          alignItems: "center",
          columnGap: "1em",
        }}
      >
        <ListItemText
          sx={{
            opacity: 0.8,
            color: extraServiceBool ? "#CF151A" : "",
          }}
        >
          {item.title}
        </ListItemText>
        {isPromotional && !!publishedCounts && (
          <LazyImage
            height="15px"
            width="51px"
            objectFit="contain"
            src="/assets/images/badges/hot-deals.png"
          />
        )}
      </FlexBox>
    </ListItemButton>
  );
};
