import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const IconFBMessenger = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      opacity={0.8}
      fill="#AEB4BE"
      height="800px"
      width="800px"
      viewBox="48.61 50.201 402.77399999999994 402.77399999999994"
    >
      <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100.001%">
        <stop offset="0" stopColor="#00b2ff" />
        <stop offset="1" stopColor="#006aff" />
      </linearGradient>

      <g fill="none" fillRule="evenodd">
        <path
          d="M250 50.201c-112.676 0-200 82.452-200 193.81 0 58.25 23.903 108.582 62.817 143.356 3.26 2.935 5.231 6.995 5.392 11.377l1.087 35.537c.362 11.337 12.072 18.694 22.455 14.151l39.678-17.487a15.974 15.974 0 0 1 10.664-.804c18.229 5.025 37.666 7.678 57.907 7.678 112.676 0 200-82.452 200-193.809S362.676 50.201 250 50.201z"
          fill="url(#a)"
        />
        <path
          d="M129.92 300.693l58.752-93.105c9.336-14.794 29.376-18.493 43.38-8l46.72 35.015a12.022 12.022 0 0 0 14.447-.04l63.099-47.84c8.41-6.391 19.437 3.7 13.762 12.624l-58.712 93.065c-9.336 14.794-29.376 18.493-43.38 8l-46.72-35.015a12.022 12.022 0 0 0-14.447.04l-63.139 47.88c-8.41 6.392-19.437-3.699-13.762-12.624z"
          fill="#ffffff"
        />
      </g>
    </SvgIcon>
  );
};
