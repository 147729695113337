import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const IconMenuTerms = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="19" height="18" viewBox="0 0 19 18" fill="none">
            <path d="M0.471105 8.97564C0.471105 6.53675 0.471105 4.09761 0.471105 1.65873C0.471105 1.33442 0.540031 1.02502 0.721089 0.754722C1.04334 0.273786 1.49984 0.00811431 2.0816 0.00682838C4.65345 0.00091313 7.2253 0.00399935 9.79714 0.00374216C11.0273 0.00374216 12.2576 0.0155727 13.4877 0.000141575C14.4746 -0.0122033 15.179 0.784556 15.1759 1.69293C15.1667 4.36328 15.1697 7.03389 15.1679 9.70424C15.1679 9.85838 15.2444 9.95483 15.3973 9.99358C16.9839 10.3956 18.1834 11.728 18.4146 13.3455C18.7325 15.5681 17.2087 17.6145 14.9884 17.9509C14.6775 17.9979 14.3681 18.0188 14.0551 17.9745C13.8625 17.9473 13.6714 18 13.4785 18C9.70636 17.999 5.93448 18.0003 2.16235 17.9977C1.6004 17.9974 1.11947 17.8048 0.782042 17.3349C0.57115 17.0412 0.473677 16.7043 0.472648 16.3442C0.469048 15.2468 0.470848 14.1497 0.470848 13.0523C0.470848 11.6936 0.470848 10.3346 0.470848 8.9759H0.471362L0.471105 8.97564ZM11.1916 16.4379C11.1499 16.3772 11.126 16.3424 11.1021 16.308C10.2912 15.1375 10.0947 13.8724 10.5841 12.5338C11.0764 11.1877 12.057 10.3565 13.4353 9.98895C13.5587 9.95603 13.6256 9.91616 13.6253 9.7706C13.6199 7.13008 13.6197 4.48982 13.6181 1.8493C13.6181 1.66001 13.5244 1.56528 13.3368 1.56511C9.65904 1.56511 5.98129 1.56511 2.30355 1.56511C2.2606 1.56511 2.21791 1.56563 2.17496 1.5664C2.08083 1.5682 2.03325 1.61629 2.03222 1.71042C2.0317 1.75337 2.03119 1.79606 2.03119 1.83901C2.03119 6.61391 2.03119 11.3891 2.03119 16.164C2.03119 16.2069 2.0317 16.2496 2.03222 16.2925C2.03325 16.3867 2.08083 16.435 2.17496 16.4366C2.21791 16.4373 2.2606 16.4379 2.30355 16.4379C5.20974 16.4379 8.11593 16.4379 11.0221 16.4379H11.1916ZM11.8996 13.9244C11.8706 15.1969 12.898 16.426 14.3918 16.4325C15.8132 16.4384 16.9019 15.3158 16.8993 13.9097C16.8965 12.4219 15.6419 11.4114 14.3766 11.4307C13.0001 11.4518 11.8981 12.5441 11.8996 13.9244Z" fill="#AEB4BE" />
            <path d="M7.83482 8.14725C6.63917 8.14725 5.44326 8.14725 4.24761 8.14725C3.97988 8.14725 3.73684 8.07755 3.55115 7.87437C3.34077 7.64419 3.29474 7.37363 3.40044 7.08482C3.50563 6.79754 3.72681 6.64117 4.0236 6.59231C4.12442 6.57559 4.22832 6.57096 4.33094 6.57096C6.6366 6.56993 8.94252 6.56993 11.2482 6.57019C11.4025 6.57019 11.5568 6.57173 11.7078 6.61057C12.0771 6.70521 12.3044 7.00432 12.2944 7.38238C12.2844 7.75504 12.0339 8.04797 11.6643 8.12307C11.5627 8.14365 11.4606 8.14776 11.3577 8.14776C10.1834 8.1475 9.00913 8.14776 7.83482 8.1475V8.14725Z" fill="#AEB4BE" />
            <path d="M7.8356 3.29725C9.06134 3.29725 10.2873 3.29494 11.5131 3.2988C11.842 3.29982 12.0912 3.45002 12.2291 3.75376C12.3595 4.04129 12.3214 4.31673 12.1213 4.56029C11.9493 4.76989 11.7127 4.85142 11.4483 4.85194C10.8398 4.85322 10.231 4.84911 9.62252 4.84911C7.80936 4.84859 5.99647 4.84962 4.18331 4.84859C3.77696 4.84859 3.49406 4.64722 3.3845 4.28793C3.23301 3.7913 3.60259 3.30548 4.14525 3.30008C4.75812 3.29391 5.37099 3.29854 5.98412 3.29854C6.60136 3.29854 7.21861 3.29854 7.83585 3.29854V3.29751L7.8356 3.29725Z" fill="#AEB4BE" />
            <path d="M6.79425 9.8565C7.66405 9.8565 8.53385 9.85418 9.40339 9.85778C9.81926 9.85958 10.1335 10.0947 10.2182 10.4529C10.3308 10.93 10.0294 11.3597 9.54279 11.414C9.45766 11.4235 9.37202 11.432 9.28663 11.432C7.59847 11.432 5.91057 11.431 4.2224 11.43C3.9287 11.43 3.67229 11.3471 3.49791 11.0923C3.32689 10.8423 3.29551 10.5782 3.4313 10.3027C3.58227 9.99641 3.84589 9.86138 4.17225 9.85881C5.04617 9.85187 5.92034 9.8565 6.79425 9.8565Z" fill="#AEB4BE" />
            <path d="M6.41773 14.7042C5.68501 14.7042 4.9523 14.7042 4.21958 14.7042C3.98991 14.7042 3.77645 14.6515 3.6013 14.4946C3.38012 14.2965 3.30991 14.0445 3.36881 13.76C3.42899 13.4684 3.62162 13.2909 3.89861 13.2022C4.01306 13.1654 4.13239 13.1516 4.25327 13.1516C5.6971 13.1526 7.1412 13.1528 8.58503 13.1541C8.90857 13.1544 9.17244 13.2732 9.3319 13.5664C9.61043 14.0784 9.24188 14.6988 8.65421 14.7029C7.90864 14.708 7.16306 14.7042 6.41773 14.7042Z" fill="#AEB4BE" />
            <path d="M13.9826 15.5303C13.7568 15.5372 13.5752 15.4359 13.4206 15.2859C13.147 15.0203 12.8764 14.7512 12.6115 14.4768C12.3448 14.2004 12.3101 13.83 12.5086 13.5075C12.6761 13.2359 13.0698 13.0855 13.388 13.1755C13.5862 13.2315 13.7218 13.3792 13.8635 13.5155C14.0103 13.6567 13.9797 13.6495 14.1176 13.5124C14.4185 13.2133 14.7161 12.9108 15.0188 12.6135C15.2165 12.4193 15.4498 12.2959 15.7355 12.3386C16.0395 12.3841 16.254 12.5559 16.3577 12.8491C16.4641 13.15 16.3903 13.4273 16.1782 13.6464C15.6327 14.2096 15.0743 14.7608 14.5157 15.3111C14.372 15.4528 14.1924 15.5354 13.9826 15.5303Z" fill="#AEB4BE" />
        </SvgIcon>
    );
};