import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const IconMenuProfileInfo = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="16" height="18" viewBox="0 0 16 18" fill="none">
            <g clipPath="url(#clip0_263_1378)">
                <path d="M7.93561 17.9982C5.53535 17.9982 3.13509 17.9982 0.734824 17.9982C0.296552 17.9982 0.00805134 17.711 0.000689287 17.2668C-0.014725 16.3288 0.174388 15.4299 0.547322 14.5727C1.26167 12.9305 2.43086 11.7204 4.0489 10.9517C4.75405 10.6168 5.49923 10.4097 6.2796 10.3559C7.11014 10.2986 7.94274 10.3453 8.77419 10.331C9.62474 10.3165 10.4553 10.4263 11.2591 10.7139C12.3816 11.1155 13.323 11.7837 14.1161 12.6648C15.0227 13.6727 15.5611 14.8587 15.7872 16.1901C15.8503 16.5607 15.8816 16.9353 15.8664 17.3116C15.857 17.5472 15.7564 17.7384 15.5685 17.8829C15.461 17.9655 15.3432 17.9998 15.2054 17.9995C12.7821 17.997 10.3589 17.9977 7.93561 17.9977V17.9982ZM7.92963 16.5952C9.17934 16.5952 10.4293 16.5952 11.679 16.5952C12.5378 16.5952 13.3964 16.5952 14.2552 16.5952C14.4119 16.5952 14.4098 16.5931 14.388 16.4399C14.3098 15.8926 14.1421 15.375 13.8968 14.8794C13.5193 14.1163 13.0239 13.447 12.3522 12.9174C11.3578 12.1338 10.2066 11.7825 8.95962 11.7307C8.10954 11.6955 7.25807 11.725 6.40867 11.7717C5.77484 11.8064 5.17 11.9776 4.5976 12.2514C3.37803 12.8348 2.48837 13.7473 1.91689 14.97C1.68982 15.4557 1.5458 15.9669 1.47218 16.4981C1.46183 16.5729 1.49059 16.5966 1.55799 16.595C1.59642 16.5941 1.63461 16.595 1.67303 16.595C3.75856 16.595 5.84409 16.595 7.92986 16.595L7.92963 16.5952Z" fill="#AEB4BE" />
                <path d="M7.95355 0.000443514C10.4838 -0.0365968 12.7764 2.09288 12.7748 4.81569C12.7732 7.5224 10.6237 9.67396 7.92042 9.67005C5.23097 9.66613 3.09482 7.52217 3.09873 4.82466C3.10264 2.0188 5.39615 -0.0211825 7.95355 0.000443514ZM4.51869 4.82972C4.48901 6.63918 5.90827 8.25423 7.93192 8.25561C9.98363 8.25676 11.3502 6.61387 11.3553 4.84445C11.3606 2.93721 9.80648 1.40936 7.93054 1.41304C6.02561 1.41672 4.49683 2.96758 4.51869 4.82972Z" fill="#AEB4BE" />
            </g>
            <defs>
                <clipPath id="clip0_263_1378">
                    <rect width="15.8703" height="18" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};