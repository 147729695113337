import {
  Container,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { colors, slugToNameUtil } from "common";
import { MenuDrawerTitle } from "./MainMenuDrawerSectionTitle";
import { isMobile } from "react-device-detect";

import { CategoryItem } from "modules/category/types";
import LazyImage from "components/LazyImage";
import { useRouter } from "next/router";
import modules from "modules";
import { useAppDispatch } from "hooks/use-app-dispatch";

const { actions: categoryActions } = modules.category;

type Props = {
  openDrawer?: boolean;
  slug?: string;
  handleAllClose?: () => void;
  handleOpenThisDrawer?: (d: string) => void;
};

export const MobileSubCategDrawer: FC<Props> = ({
  openDrawer,
  slug,
  handleAllClose,
  handleOpenThisDrawer,
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const [categoriesUnderCurrentMain, setCategoriesUnderMain] = useState<
    CategoryItem[]
  >([]);
  const [title, setTitle] = useState<string>("");

  const isManufacturers = useMemo(
    () => (title === "Manufacturers" ? true : false),
    [title]
  );

  const srcImage = useCallback(
    (item: CategoryItem) => {
      if (isManufacturers) {
        return item?.img_url ?? "/assets/images/sad_red_hair.png";
      } else {
        return item?.icon_sidedrawer ?? "/assets/images/sad_red_hair.png";
      }
    },
    [isManufacturers]
  );

  const getSubCategories = async () => {
    const response: any = await dispatch(
      categoryActions.getMainCategoryDetailsThunk({ slug: slug })
    );

    if (response) {
      const data = response?.payload?.originalData?.data;
      const newTitle =
        data?.target_data_id === 2 ? "Manufacturers" : slugToNameUtil(slug);

      setCategoriesUnderMain(data ? data.target_data : []);
      setTitle(newTitle);
    }
  };

  const onClickCategoryItem = (item) => {
    if (title === "Manufacturers") {
      router.push({
        pathname: `/search/`,
        query: {
          slug: slug,
          manufacturer: item.name,
          page: 1,
        },
      });
    } else if (item?.id) {
      router.push({
        pathname: `/search/`,
        query: {
          slug: slug,
          category: item?.name,
          page: 1,
        },
      });
    }
    handleAllClose();
  };

  useEffect(() => {
    isMobile && openDrawer ? getSubCategories() : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, openDrawer]);

  return isMobile ? (
    <Drawer
      open={openDrawer}
      anchor="right"
      onClose={handleAllClose}
      sx={{
        color: colors.backgroundGrey,
      }}
    >
      <Container sx={{ width: "70vw", padding: 0 }}>
        <List sx={{ textAlign: "center" }}>
          <ListItemButton onClick={() => handleOpenThisDrawer("menu")}>
            <ListItemIcon>
              <TurnLeftIcon opacity={0.7} />
            </ListItemIcon>
            <ListItemText sx={{ opacity: 0.8 }}>Back</ListItemText>
          </ListItemButton>
          <Divider sx={{ bgcolor: colors.backgroundGrey }} />
        </List>
        <List>
          <MenuDrawerTitle title={title} />
          {categoriesUnderCurrentMain.map((item) =>
            item?.is_visible_sidedrawer || isManufacturers ? (
              <ListItemButton
                key={item?.name}
                onClick={() => {
                  onClickCategoryItem(item);
                }}
              >
                <ListItemIcon>
                  <LazyImage
                    width={isManufacturers ? 40 : 24}
                    height={24}
                    objectFit="contain"
                    src={srcImage(item)}
                    alt="kachi"
                  />
                </ListItemIcon>
                <ListItemText sx={{ opacity: 0.8 }}>{item.name}</ListItemText>
              </ListItemButton>
            ) : null
          )}
        </List>
      </Container>
    </Drawer>
  ) : null;
};
