import { Box, Collapse } from "@mui/material";
import React, { FC, memo } from "react";
import Link from "next/link";
import BranchInfo from "./BranchInfo";
import { CompanyInfoBranches } from "modules/branch";

interface Props {
  open: boolean;
  branchesData: CompanyInfoBranches[];
}

export const MobileBranchesInfo: FC<Props> = ({ open, branchesData }) => {
  return (
    <Collapse in={open} sx={{ paddingLeft: "2.3em" }}>
      {branchesData.map((branch, index) => (
        <div key={index}>
          <BranchInfo
            branchName={branch.branchName}
            location={branch.location}
            href={branch.gMapsPin}
            telNo={branch.telNo}
            cellNo={branch.cellNo}
            cellNo2={branch.cellNo2}
          />
        </div>
      ))}
    </Collapse>
  );
};
