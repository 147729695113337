import modules from "modules";
import { useAppSelector } from "../use-app-selector";
import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../use-app-dispatch";

const { selectors: promotionalSelectors, actions: promotionalActions } =
  modules.promotional;

export const usePromotionalCounts = () => {
  const dispatch = useAppDispatch();
  const publishedCounts = useAppSelector(promotionalSelectors.selectCounts);

  const getPublishedCounts = useCallback(() => {
    publishedCounts === null &&
      dispatch(promotionalActions.getPromotionalCountsThunk());
  }, [dispatch, publishedCounts]);

  return {
    publishedCounts,
    getPublishedCounts,
  };
};
