import { ListItemText, Typography, ListItemAvatar, Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors } from "common";
import { IconStore } from "components/icons/IconStore";
import { iconStyle } from "components/mobile-navigation/styles";
import CloseIcon from '@mui/icons-material/Close';
import { FC } from "react";

type BranchHeaderProps = {
  branch: string;
  branchOpen: boolean;
  toggleBranchOpen: (v: boolean) => void;
}

const useStyles = makeStyles({
  branchName: {
    color: colors.skyBlue
  },
})

export const BranchListHeader: FC<BranchHeaderProps> = ({
  branch,
  branchOpen,
  toggleBranchOpen,
}) => {
  const classes = useStyles()

  if (branchOpen) {
    return (
      <Grid container columns={12} alignItems={'center'}>
        <Grid item xs={11} md={11}>
          <Typography variant={'h6'} fontWeight={'bold'}>
            {`Branches`}
          </Typography>
        </Grid>
        <Grid item container xs={1} md={1} justifyContent={'end'} >
          <Button sx={{ padding: 0 }} onClick={() => toggleBranchOpen(false)}>
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <ListItemAvatar>
        <IconStore sx={iconStyle} />
      </ListItemAvatar>
      <ListItemText>
        <Typography variant={'h5'} fontWeight={'bold'}>
          {`You're in `}
          <span className={classes.branchName}>
            {branch}
          </span>
        </Typography>
      </ListItemText>
    </>
  )
}