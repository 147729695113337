import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const IconMenuAccount = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M3.06363 4.81987C3.0418 2.15688 5.33011 -0.107999 7.93406 0.00410808C10.1043 -0.101566 12.7397 1.77944 12.7275 4.88626C12.7177 7.43393 10.6184 9.70018 7.81667 9.66779C5.11118 9.63655 3.03767 7.35674 3.06363 4.81987ZM10.9676 4.81987C10.9506 3.1787 9.68963 1.74521 7.89409 1.74681C6.01814 1.74842 4.80932 3.27128 4.82081 4.85065C4.83229 6.41325 6.04686 7.90211 7.89891 7.90464C9.7875 7.90716 10.9603 6.32917 10.9676 4.81987Z" fill="#AEB4BE" />
            <path d="M8.12313 10.6416C8.96577 10.6285 9.76912 10.7376 10.5484 11.0053C10.8812 11.1197 11.1059 11.3386 11.1741 11.6905C11.3023 12.3531 10.6536 12.9104 10.0675 12.6942C9.63082 12.5332 9.17137 12.4739 8.70709 12.4424C8.25568 12.4116 7.80381 12.4123 7.35285 12.4268C6.24419 12.4626 5.25568 12.8323 4.41098 13.5559C3.47783 14.3554 2.95198 15.3786 2.78359 16.5911C2.75051 16.83 2.78267 17.0744 2.73213 17.3108C2.66138 17.6423 2.45554 17.8874 2.13622 17.9651C1.54284 18.1091 1.01125 17.7647 1.00068 17.1401C0.982305 16.051 1.23707 15.0206 1.76521 14.068C2.35768 12.9995 3.1631 12.1268 4.22628 11.5088C5.03009 11.0416 5.90259 10.7914 6.82655 10.7091C7.26487 10.6701 7.70319 10.6331 8.12313 10.6416Z" fill="#AEB4BE" />
            <path d="M11.3492 15.2474C11.1034 15.2348 10.7652 15.2782 10.4307 15.2263C9.99241 15.158 9.68251 14.7912 9.68894 14.3639C9.69561 13.9311 9.99678 13.5819 10.4305 13.5136C10.7347 13.4659 11.0418 13.4989 11.3476 13.493C11.6651 13.4868 11.983 13.4877 12.3007 13.4953C12.4149 13.498 12.4429 13.4576 12.442 13.3501C12.4379 12.7875 12.4372 12.2246 12.4413 11.662C12.4445 11.2348 12.6428 10.943 13.0179 10.7955C13.5382 10.5908 14.1192 10.9329 14.1985 11.487C14.2235 11.6627 14.2159 11.8385 14.2169 12.014C14.2192 12.458 14.2164 12.9021 14.2164 13.3462C14.2164 13.4923 14.2194 13.4941 14.3634 13.4939C14.9223 13.4934 15.4813 13.4875 16.04 13.495C16.4158 13.5001 16.7206 13.6496 16.8826 14.0066C17.1535 14.6035 16.7298 15.2384 16.0563 15.2462C15.4937 15.2527 14.9308 15.2476 14.3682 15.2469C14.2173 15.2469 14.2164 15.2462 14.2166 15.4011C14.2169 15.9446 14.221 16.4881 14.2173 17.0317C14.2152 17.3432 14.1254 17.6237 13.8658 17.8199C13.3747 18.1913 12.5918 17.9781 12.4624 17.2713C12.4466 17.1844 12.4383 17.0974 12.4385 17.0089C12.4399 16.4693 12.4374 15.9294 12.4422 15.3898C12.4431 15.2821 12.4153 15.2419 12.3009 15.2449C12.014 15.2524 11.7266 15.2474 11.3492 15.2474Z" fill="#AEB4BE" />
        </SvgIcon>
    );
};