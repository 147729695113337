import Marquee from "react-fast-marquee";
import { Box, Container, styled } from "@mui/material";
import { deliveryNote } from "common";
import { FlexBox } from "components/flex-box";
import { Small } from "components/Typography";
import { layoutConstant } from "utils/constants";

const TopbarWrapper = styled(Box)(({ theme }) => ({
  fontSize: 12,
  height: layoutConstant.topbarDeliveryHeight,
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  "& .topbarLeft": {
    "& .logo": { display: "none" },
    "& .title": { marginLeft: "10px" },
    "@media only screen and (max-width: 900px)": {
      "& .logo": { display: "block" },
      "& > *:not(.logo)": { display: "none" },
    },
  },
  "& .topbarRight": {
    "& .link": {
      paddingRight: 30,
      color: theme.palette.secondary.contrastText,
    },

    "@media only screen and (max-width: 900px)": {
      "& .link": { display: "none" },
    },
  },
  "& .menuItem": { minWidth: 100 },
  "& .marginRight": { marginRight: "1.25rem" },
  "& .handler": { height: layoutConstant.topbarDeliveryHeight },
  "& .smallRoundedImage": { height: 15, width: 25, borderRadius: 2 },
  "& .menuTitle": { fontSize: 12, marginLeft: "0.5rem", fontWeight: 600 },
}));

const DeliveryHeader = () => {
  return (
    <TopbarWrapper>
      <Container
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FlexBox className="topbarRight" alignItems="center">
          <Marquee gradient={false}>
            <Small>{deliveryNote}</Small>
          </Marquee>
        </FlexBox>
      </Container>
    </TopbarWrapper>
  );
};

export default DeliveryHeader;
