import { FC, useCallback, useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Container,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import { colors, drjInfo, openLinkWithApps, socialMediaLinks } from "common";
import BazarIconButton from "components/BazarIconButton";
import Image from "components/BazarImage";
import { FlexBox } from "components/flex-box";
import Facebook from "components/icons/Facebook";
import Instagram from "components/icons/Instagram";
import TikTok from "components/icons/Tiktok";
import { H6, Paragraph, Span } from "components/Typography";
import { useCompanyInfo, useIsMobile } from "hooks";
import Link from "next/link";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import modules from "modules";
import { useAppSelector } from "hooks/use-app-selector";
import BranchInfo from "./BranchInfo";
import { East } from "@mui/icons-material";
import { MobileInformation } from "./MobileInformation";
import { MobileBranchesInfo } from "./MobileBranchesInfo";

const { selectors: branchesSelectors, actions: branchesActions } =
  modules.branch;

const Viber = () => {
  return (
    <Icon style={{ display: "flex" }}>
      <Image
        src="/assets/images/icons/viber-white.svg"
        alt="viber"
        width={24}
        height={24}
      />
    </Icon>
  );
};

interface IconProps {
  expanded: boolean;
}

const Footer: FC = () => {
  const isMobile = useIsMobile();

  const { companyInfoData } = useCompanyInfo();

  const branchesData = useAppSelector(branchesSelectors.selectBranches);
  const [isBranchesExpand, setIsBranchesExpand] = useState<boolean>(false);
  const [isInfoExpand, setIsInfoExpand] = useState<boolean>(false);

  const toggleBranchesExpand = () => {
    setIsBranchesExpand(!isBranchesExpand);
  };

  const toggleInfoExpand = () => {
    setIsInfoExpand(!isInfoExpand);
  };

  const iconList = [
    {
      icon: Facebook,
      url: companyInfoData.facebook,
      androidUrl: companyInfoData.facebookUrlAndroid,
      iosUrl: companyInfoData.facebookUrlIOS,
    },
    { icon: TikTok, url: companyInfoData.tiktok },
    {
      icon: Instagram,
      url: companyInfoData.instagram,
      androidUrl: companyInfoData.instagramAndroid,
      iosUrl: companyInfoData.instagramIos,
    },
    {
      icon: Viber,
      url: drjInfo?.viberInvite,
      androidUrl: drjInfo?.viberInvite,
      iosUrl: drjInfo?.viberInvite,
    },
  ];

  const SectionIcon: FC<IconProps> = ({ expanded }) => {
    if (!isMobile) {
      return null;
    }
    if (expanded) {
      return <ExpandLessIcon fontSize="small" />;
    }
    return <ExpandMoreIcon fontSize="small" />;
  };

  return (
    <footer>
      <Box bgcolor={colors.primary}>
        <Container sx={{ p: "1rem", color: "white" }}>
          <Box pt={4} pb={isMobile ? 2 : 4} overflow="hidden">
            <Grid justifyContent="center" container spacing={isMobile ? 0 : 3}>
              <Grid item mt={-2} lg={4} md={4} sm={12} xs={12}>
                <Link href="/">
                  <a>
                    <Image
                      height={50}
                      src="/assets/images/logos/logo_tagline_white.png"
                      alt="logo"
                    />
                  </a>
                </Link>
                <Paragraph mb={2}>{companyInfoData.copyright}</Paragraph>

                {!isMobile ? (
                  <Box>
                    <Paragraph color="grey.500">
                      Owned and Operated by:
                    </Paragraph>
                    <H6 mb={2.5}>{companyInfoData.name}</H6>
                  </Box>
                ) : null}

                {!isMobile && (
                  <Box minWidth={"100px"} mt={"4rem"}>
                    <Image
                      height={"auto"}
                      width={"100%"}
                      src="/assets/images/payment_options.png"
                      alt="logo"
                    />
                  </Box>
                )}
              </Grid>

              <Grid item lg={3.5} md={3} sm={12} xs={12}>
                <Box
                  fontSize="25px"
                  fontWeight="600"
                  mb={isMobile ? "0px" : 2.5}
                  lineHeight="1"
                  color="white"
                  display="flex"
                  alignItems="center"
                >
                  <FlexBox mb={1} gap={1}>
                    {isMobile ? (
                      <Span onClick={toggleBranchesExpand}>
                        <SectionIcon expanded={isBranchesExpand} />
                        <Span color="white" ml={1} fontSize={{ xs: "20px" }}>
                          Branches
                        </Span>
                      </Span>
                    ) : (
                      <Span color="white">Branches</Span>
                    )}
                    <Link href={"/branches"} passHref>
                      <IconButton sx={{ my: 0.5 }} style={{ color: "#fff" }}>
                        <East
                          sx={{
                            fontSize: 19,
                            transform: ({ direction }) =>
                              `rotate(${
                                direction === "rtl" ? "180deg" : "0deg"
                              })`,
                          }}
                        />
                      </IconButton>
                    </Link>
                  </FlexBox>
                </Box>
                {isMobile ? (
                  <MobileBranchesInfo
                    open={isBranchesExpand}
                    branchesData={branchesData}
                  />
                ) : (
                  <>
                    {branchesData.map((branch, index) => (
                      <div key={index}>
                        <BranchInfo
                          branchName={branch.branchName}
                          location={branch.location}
                          href={branch.gMapsPin}
                          telNo={branch.telNo}
                          cellNo={branch.cellNo}
                          cellNo2={branch.cellNo2}
                        />
                      </div>
                    ))}
                  </>
                )}
              </Grid>

              <Grid item lg={3} md={4} sm={12} xs={12}>
                <Box>
                  <Box
                    fontSize="25px"
                    fontWeight="600"
                    mb={1}
                    lineHeight="1"
                    color="white"
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    {isMobile ? (
                      <Span onClick={toggleInfoExpand}>
                        <SectionIcon expanded={isInfoExpand} />
                        <Span color="white" ml={1} fontSize={{ xs: "20px" }}>
                          Information
                        </Span>
                      </Span>
                    ) : (
                      <Span color="white">Information</Span>
                    )}
                  </Box>
                  {isMobile ? (
                    <MobileInformation open={isInfoExpand} />
                  ) : (
                    <>
                      <Box py={0.6}>
                        <Link href={"/corporate"}>PC Worth Corporate</Link>
                      </Box>
                      <Box py={0.6}>
                        <Link href={"/contact-us"}>Contact Us</Link>
                      </Box>
                      <Box py={0.6}>
                        <Link href={"/terms-and-conditions"}>
                          Terms & Conditions
                        </Link>
                      </Box>
                      <Box py={0.6}>
                        <Link href={"/how-to-order"}>How To Order</Link>
                      </Box>
                      <Box py={0.6}>
                        <Link href={"/frequently-ask-question"}>
                          Frequently Ask Question
                        </Link>
                      </Box>
                      <Box py={0.6}>
                        <Link href={"/privacy-policy"}>Privacy Policy</Link>
                      </Box>
                    </>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", lg: "column" },
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Box
                    fontSize={{ xs: "18px", lg: "25px" }}
                    fontWeight="600"
                    lineHeight="1"
                    color="white"
                    mt={{ xs: 0, lg: "1rem" }}
                    mr={{ xs: "1rem", lg: 0 }}
                  >
                    Follow Us
                  </Box>
                  <FlexBox className="flex" my={1} mx={-0.625}>
                    {iconList.map((item, ind) => (
                      <BazarIconButton
                        m={0.5}
                        key={ind}
                        bgcolor="rgba(0,0,0,0.2)"
                        fontSize="20px"
                        padding="10px"
                        onClick={() =>
                          openLinkWithApps(
                            item?.url,
                            item?.iosUrl,
                            item?.androidUrl
                          )
                        }
                      >
                        <item.icon fontSize="inherit" />
                      </BazarIconButton>
                    ))}
                  </FlexBox>
                </Box>
              </Grid>

              {isMobile && (
                <Grid lg={12} md={12} sm={12} xs={12}>
                  <Box
                    width={"100%"}
                    sx={{ display: "grid", placeItems: "center" }}
                    mt={1}
                  >
                    <Image
                      height={"auto"}
                      width={"87%"} //safe from overflowing
                      src="/assets/images/payment_options.png"
                      alt="logo"
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
